import React, { useState } from 'react';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import iconv from 'iconv-lite';
import * as ExcelJS from 'exceljs';

interface ProcessedData {
  originalNames: string[];
  xlsxBuffer?: ArrayBuffer;
}

interface NameMapping {
  newName: string;
  newPhotometry: string;
  oldPhotometry: string;
}

interface RowData {
  name: string;
  photometry: string;
}

const BimConvertor: React.FC = () => {
  const [processedData, setProcessedData] = useState<ProcessedData | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [nameMapping, setNameMapping] = useState<Map<string, NameMapping>>(new Map());
  const [originalZipFile, setOriginalZipFile] = useState<File | null>(null);
  const [seriesName, setSeriesName] = useState<string>('');
  const [manufacter, setManufacter] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const handleBimUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setOriginalZipFile(file);

    try {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(file);
      
      const txtFile = Object.values(zipContent.files).find(file => 
        !file.dir && file.name.endsWith('.txt') && !file.name.includes('/')
      );

      if (!txtFile) {
        throw new Error('TXT файл не найден в корне архива');
      }

      const binaryContent = await txtFile.async('uint8array');
      const content = iconv.decode(Buffer.from(binaryContent), 'utf16le');

      Papa.parse(content, {
        delimiter: ',',
        header: false,
        complete: async (results: any) => {
          if (results.data && results.data.length > 0) {
            const headers = results.data[0];
            const photometryIndex = headers.findIndex((h: string) => 
              h.includes('Файл фотометрической сетки##OTHER##')
            );
            
            const rows = results.data.slice(1)
              .map((row: any[]) => ({
                name: row[0]?.trim(),
                photometry: (photometryIndex !== -1 ? row[photometryIndex] : row[1])?.trim(),
              }))
              .filter((row: any) => row.name && row.name.length > 1);

            // Создаем новый Excel файл
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Products');

            // Добавляем заголовки
            worksheet.addRow([
              'Наименование Promled',
              'Наименование партнера',
              'Файл фотометрии Promled',
              'Имя файла фотометрии партнера'
            ]);

            // Стилизуем заголовки
            const headerRow = worksheet.getRow(1);
            headerRow.font = { bold: true };

            // Добавляем данные
            rows.forEach((row: RowData) => {
              worksheet.addRow([row.name, '', row.photometry, '']);
            });

            // Устанавливаем ширину колонок
            worksheet.columns = [
              { width: 50 }, // A
              { width: 50 }, // B
              { width: 45 }, // C
              { width: 45 }  // D
            ];

            // Генерируем буфер
            const buffer = await workbook.xlsx.writeBuffer();

            setProcessedData({
              originalNames: rows.map((r: any) => r.name),
              xlsxBuffer: buffer
            });
            setUploadedFile(file);
          }
        },
        error: (error: any) => {
          console.error('Ошибка парсинга CSV:', error);
          alert('Ошибка при обработке файла');
        }
      });

    } catch (error) {
      console.error('Ошибка обработки архива:', error);
      alert('Ошибка при обработке архива');
    }
  };

  const downloadTemplate = () => {
    if (!processedData?.xlsxBuffer) {
      alert('Ошибка: файл не сгенерирован');
      return;
    }

    const blob = new Blob([processedData.xlsxBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'products.xlsx';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const buffer = await file.arrayBuffer();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) {
        throw new Error('Не удалось найти лист в файле Excel');
      }
      
      const data: any[] = [];
      
      // Преобразуем данные в массив объектов
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return; // Пропускаем заголовки
        const rowData: any = {};
        row.eachCell((cell, colNumber) => {
          const header = worksheet.getRow(1).getCell(colNumber).value?.toString() || '';
          rowData[header] = cell.value;
        });
        data.push(rowData);
      });

      // Проверка дублей
      const newNames = new Set<string>();
      const duplicateNewNames = new Set<string>();
      const newPhotometry = new Set<string>();
      const duplicatePhotometry = new Set<string>();

      data.forEach((row: any) => {
        const newName = row['Наименование партнера'];
        const newPhoto = row['Имя файла фотометрии партнера'];

        if (newName) {
          if (newNames.has(newName)) {
            duplicateNewNames.add(newName);
          } else {
            newNames.add(newName);
          }
        }

        if (newPhoto) {
          if (newPhotometry.has(newPhoto)) {
            duplicatePhotometry.add(newPhoto);
          } else {
            newPhotometry.add(newPhoto);
          }
        }
      });

      // Если найдены дубли, создаем новый файл с подсветкой
      if (duplicateNewNames.size > 0 || duplicatePhotometry.size > 0) {
        // Создаем новый файл для отчета о дублях
        const newWorkbook = new ExcelJS.Workbook();
        const newWorksheet = newWorkbook.addWorksheet('Products');

        // Копируем заголовки
        const headers = worksheet.getRow(1).values as string[];
        newWorksheet.addRow(headers);

        // Стилизуем заголовки
        const headerRow = newWorksheet.getRow(1);
        headerRow.font = { bold: true };

        // Добавляем данные и подсвечиваем дубли
        data.forEach((rowData: any) => {
          const row = newWorksheet.addRow(Object.values(rowData));
          
          // Находим индексы нужных колонок
          const partnerNameIndex = headers.indexOf('Наименование партнера');
          const photometryIndex = headers.indexOf('Имя файла фотометрии партнера');

          // Подсвечиваем дубли красным
          if (duplicateNewNames.has(rowData['Наименование партнера'])) {
            const cell = row.getCell(partnerNameIndex);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFF0000' }
            };
          }

          if (duplicatePhotometry.has(rowData['Имя файла фотометрии партнера'])) {
            const cell = row.getCell(photometryIndex);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFF0000' }
            };
          }
        });

        // Устанавливаем ширину колонок
        newWorksheet.columns = [
          { width: 50 }, // A
          { width: 50 }, // B
          { width: 45 }, // C
          { width: 45 }  // D
        ];

        // Сохраняем файл
        const buffer = await newWorkbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'duplicates_found.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        alert('Найдены дубликаты! Проверьте выгруженный файл с подсвеченными дублями.');
        return;
      }

      // Если дублей нет, продолжаем как обычно
      const newNameMapping = new Map<string, NameMapping>();
      data.forEach((row: any) => {
        if (row['Наименование Promled']) {
          newNameMapping.set(row['Наименование Promled'], {
            newName: row['Наименование партнера'] || '',
            newPhotometry: row['Имя файла фотометрии партнера'] || '',
            oldPhotometry: row['Файл фотометрии Promled'] || ''
          });
        }
      });

      setNameMapping(newNameMapping);
      setUploadedFile(file);
    } catch (error) {
      console.error('Ошибка при чтении файла:', error);
      alert('Ошибка при чтении файла');
    }
  };

  const generateArchive = async () => {
    if (!processedData || !nameMapping || !originalZipFile) {
      alert('Необходимо загрузить все файлы');
      return;
    }

    setIsGenerating(true);
    setProgress(0);

    try {
      const newZip = new JSZip();
      const oldZip = await JSZip.loadAsync(originalZipFile);
      setProgress(10);

      const txtFile = Object.values(oldZip.files).find(file => 
        !file.dir && file.name.endsWith('.txt') && !file.name.includes('/')
      );

      if (!txtFile) {
        throw new Error('TXT файл не найден в корне архива');
      }

      const binaryContent = await txtFile.async('uint8array');
      let content = iconv.decode(Buffer.from(binaryContent), 'utf16le');

      const lines = content.split('\n');
      
      const headers = lines[0].split(',');
      
      const hasDescription = headers.some(h => h === 'Описание##OTHER##');
      
      if (!hasDescription) {
        headers.push('Описание##OTHER##');
      }
      
      lines[0] = headers.join(',');

      const columnIndexes = {
        name: headers.findIndex(h => h.includes('ADSK_Наименование##OTHER##')),
        photometry: headers.findIndex(h => h.includes('Файл фотометрической сетки##OTHER##')),
        manufacturer: headers.findIndex(h => h.includes('ADSK_Завод-изготовитель##OTHER##')),
        url: headers.findIndex(h => h.includes('URL##OTHER##')),
        description: headers.findIndex(h => h.includes('ADSK_Описание##OTHER##')),
        otherDescription: headers.findIndex(h => h === 'Описание##OTHER##')
      };

      const updatedLines = lines.map((line, index) => {
        if (index === 0) return line;
        if (!line.trim()) return '';
        
        const columns = line.split(',');
        const oldName = columns[0]?.trim();
        const mapping = nameMapping.get(oldName);
        
        if (!mapping) return '';
        
        if (mapping.newName) {
          columns[0] = mapping.newName;
          
          if (columnIndexes.name !== -1) {
            columns[columnIndexes.name] = mapping.newName;
          }

          if (columnIndexes.photometry !== -1 && mapping.newPhotometry) {
            columns[columnIndexes.photometry] = mapping.newPhotometry;
          }
        }

        if (columnIndexes.manufacturer !== -1) {
          columns[columnIndexes.manufacturer] = manufacter;
        }

        if (columnIndexes.url !== -1) {
          columns[columnIndexes.url] = '-';
        }
        if (columnIndexes.description !== -1) {
          columns[columnIndexes.description] = '-';
        }

        if (columnIndexes.otherDescription !== -1) {
          columns[columnIndexes.otherDescription] = '-';
        } else {
          columns.push('-');
        }

        return columns.join(',');
      }).filter(line => line !== '');

      content = updatedLines.join('\n');

      const updatedContent = iconv.encode(content, 'windows-1251');
      newZip.file(`${seriesName}.txt`, updatedContent);
      setProgress(30);

      const iesFiles = Object.values(oldZip.files).filter(file => 
        !file.dir && 
        (file.name.toLowerCase().includes('ies/') || file.name.toLowerCase().includes('IES/')) && 
        file.name.toLowerCase().endsWith('.ies')
      );

      const getNewIESdata = (oldPhotometry: string): [string | undefined, string | undefined] => {
        const entries = Array.from(nameMapping.entries());
        for (const [, mapping] of entries) {
          if (mapping.oldPhotometry === oldPhotometry) {
            return [mapping.newPhotometry, mapping.newName];
          }
        }
        return [undefined, undefined];
      };

      for (const iesFile of iesFiles) {
        const oldName = iesFile.name.split('/').pop();
        if (!oldName) continue;
        const newIESdata = getNewIESdata(oldName.replace('.ies', ''));

        if (newIESdata[0]) {
          const fileContent = await iesFile.async('text');
          const newLuminaireName = newIESdata[1];
          
          if (newLuminaireName) {
            const updatedContent = fileContent
              .replace(
                /(\[TESTLAB\]).*/, 
                `$1 LAB`
              )
              .replace(
                /(\[LUMINAIRE\]).*/, 
                `$1 ${newLuminaireName}`
              )
              .replace(
                /(\[MANUFAC\]).*/, 
                `$1 ${manufacter}`
              );
            
            const newPath = iesFile.name.replace(oldName, `${newIESdata[0]}.ies`);
            newZip.file(newPath, updatedContent);
          }
        }
      }
      setProgress(70);

      for (const [path, file] of Object.entries(oldZip.files)) {
        if (!file.dir && 
            !path.toLowerCase().includes('ies/') && 
            !path.toLowerCase().includes('IES/') &&
            !path.toLowerCase().endsWith('.pdf')) {
          
          const content = await file.async('uint8array');
          
          if (!path.includes('/')) {
            const extension = path.toLowerCase().split('.').pop() || '';
            if (extension === 'rfa') {
              const newPath = `${seriesName}.${extension}`;
              newZip.file(newPath, content);
            } else if (extension === 'bat') {
              let fileContent = await file.async('text');
              fileContent = fileContent.replace(/PROMLED/gi, manufacter);
              newZip.file(path, fileContent);
            }
          } else {
            newZip.file(path, content);
          }
        }
      }
      setProgress(90);

      const updatedZip = await newZip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 9 }
      });
      setProgress(100);

      const url = window.URL.createObjectURL(updatedZip);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${seriesName}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Ошибка при генерации архива:', error);
      alert('Ошибка при генерации архива');
    } finally {
      setIsGenerating(false);
      setProgress(0);
    }
  };

  return (
    <div className="p-10 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Конвертор BIM моделей</h1>
      
      <div className="space-y-8">
        <div className="border rounded-lg p-4 bg-white shadow-sm">
          <div className="flex items-center mb-4">
            <div className="w-8 h-8 rounded-full flex items-center justify-center border-2 
              border-blue-500 bg-blue-500 text-white mr-3">
              1
            </div>
            <div>
              <div className="text-xl font-medium">Загрузите архив с BIM моделями PromLED</div>
            </div>
          </div>
          
          <div className="ml-11">
            <div>
              <div className="text-s text-gray-500">1.1 Скачайте архив с BIM моделями на странице продукта promled.com</div>
              <div className="text-s text-gray-500">1.2 Выберите архив с BIM моделями</div>
            </div>
            <div className="text-sm text-gray-600 mb-4">
              Например: BIM-promled-profi_neo-#-#-#-#-#-#-#-#-#-#-#.zip
            </div>
            <label className="block">
              <input
                type="file"
                accept=".zip,.rar,.7z"
                onChange={handleBimUpload}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
              />
            </label>
          </div>
        </div>

        <div className={`border rounded-lg p-4 bg-white shadow-sm ${uploadedFile ? 'opacity-100' : 'opacity-50'}`}>
          <div className="flex items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
              ${uploadedFile ? 'border-blue-500 bg-blue-500 text-white' : 'border-gray-300 bg-white text-gray-500'} mr-3`}>
              2
            </div>
            <div>
              <div className="text-xl pt-4 font-medium mb-4">Скачать таблицу светильников PromLED</div>
            </div>
          </div>
          
          <div className="ml-11">
            <div>
              <div className="text-s text-gray-500">2.1 Скачайте таблицу светильников PromLED из загруженного архива в xlsx формате</div>
              <div className="text-s text-gray-500">2.2 Заполните колонку B "Наименование партнера" вашими наименованиями светильников</div>
              <div className="text-s text-gray-500">2.3 Заполните колонку D "Имя файла фотометрии партнера" желаемыми названиями новых файлов фотометрии </div>
              <div className="text-s text-gray-500">2.4 После заполнения файла, загрузите его в шаг 3</div>
              <div className="text-s text-red-500 pt-2 font-bold">Внимание! Не изменяйте первую строку файла (заголовки)</div>
              <div className="text-s text-gray-500">Если вам не нужна полная таблица со всеми светильниками, вы можете удалить ненужные строки из файла. В итоговой BIM модели серии останутся только те светильники, которые вы оставили в таблице</div>
            </div>
            <button 
              onClick={downloadTemplate}
              disabled={!processedData?.xlsxBuffer}
              className={`mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center gap-2
                ${!processedData?.xlsxBuffer && 'opacity-50 cursor-not-allowed'}`}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              Скачать таблицу светильников из архива BIM модели
            </button>
          </div>
        </div>

        <div className={`border rounded-lg p-4 bg-white shadow-sm ${processedData ? 'opacity-100' : 'opacity-50'}`}>
          <div className="flex items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
              ${processedData ? 'border-blue-500 bg-blue-500 text-white' : 'border-gray-300 bg-white text-gray-500'} mr-3`}>
              3
            </div>
            <div>
              <div className="text-xl font-medium mt-4 mb-4">Загрузка таблицы сопоставления названий</div>
            </div>
          </div>
          
          <div className="ml-11">
            <div className="text-s text-gray-600 mb-4">
              После заполнения таблицы соответствий названий PromLED и ваших наименований, загрузите файл обратно.
            </div>
            <label className="block">
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                disabled={!processedData}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100
                  disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </label>
          </div>
        </div>

        <div className={`border rounded-lg p-4 bg-white shadow-sm ${uploadedFile && processedData ? 'opacity-100' : 'opacity-50'}`}>
          <div className="flex items-center mb-4">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 
              ${uploadedFile && processedData ? 'border-blue-500 bg-blue-500 text-white' : 'border-gray-300 bg-white text-gray-500'} mr-3`}>
              4
            </div>
            <div>
              <div className="text-xl pt-4 font-medium mb-4">Генерация архива с BIM моделями с новыми названиями</div>        
            </div>
          </div>
          
          <div className="ml-11">
            <div className="max-w-2xl text-gray-600 mb-4">
                <label htmlFor="seriesName" className="text-s block mb-1">4.1 Введите свое название серии:</label>
                <input 
                  id="seriesName" 
                  type="text" 
                  value={seriesName}
                  onChange={(e) => setSeriesName(e.target.value)}
                  placeholder="Например: GoLED Profi NEO" 
                  className="w-full p-2 border border-gray-300 rounded-md" 
                />
                <label htmlFor="manufacter" className="text-s block mb-1 mt-4">4.2 Введите свое название производителя:</label>
                <input 
                  id="manufacter" 
                  type="text" 
                  value={manufacter}
                  onChange={(e) => setManufacter(e.target.value)}
                  placeholder="Например: GoLED" 
                  className="w-full p-2 border border-gray-300 rounded-md" 
                />
            </div>
            <div className="text-s text-gray-500 pb-4">4.3 Нажмите кнопку "Сгенерировать архив". Автоматически сгенерируется архив с BIM моделями с новыми наименованиями, новыми файлами фотометрии, новым названием производителя и серии</div>
            {isGenerating && (
              <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <div className="text-sm text-gray-600 mt-1 text-center">
                  {progress}% завершено
                </div>
              </div>
            )}

            <button 
              onClick={generateArchive}
              disabled={!(uploadedFile && processedData) || isGenerating}
              className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
                ${(!(uploadedFile && processedData) || isGenerating) && 'opacity-50 cursor-not-allowed'}`}
            >
              {isGenerating ? 'Генерация архива...' : 'Сгенерировать архив'}
            </button>
            <div className="text-s text-red-500 pt-4">4.4 Перешлите архив своему менеджеру для финальной загрузки в формат BIM</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BimConvertor; 