import React from 'react';
import BimConvertor from './components/BimConvertor';

const App: React.FC = () => {
  return (
    <div>
      <BimConvertor />
    </div>
  );
};

export default App; 